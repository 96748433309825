/* Lists */
#lists ul {
  margin: 0 0 1rem;
  padding: 0;
}
#lists li {
  margin: 0;
  font-size: 1.2rem;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ccc;
}

#lists li:last-child {
  border-bottom: 1px solid #ccc;
}
#lists li:hover {
  background-color: var(--brand-light);
}
#lists li a {
  display: block;
  padding: 1rem;
  color: var(--foreground);
  text-decoration: none;
  flex-grow: 1;
}
#lists li .icon {
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  margin: 5px;
  border: 0;
  border-radius: 3px;

  background-color: var(--background);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
#lists li .icon.edit {
  background-image: url("../../../public/icons/edit.png");
}
#lists li .icon.delete {
  background-image: url("../../../public/icons/delete.png");
}
#lists li .icon.done {
  background-image: url("../../../public/icons/done.png");
}

#lists li .icon:hover {
  background-color: var(--brand);
}

#lists form {
  padding: 0.5rem;
}

.list-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.list-header h2 {
  margin: 0 1rem 0 0;
}
.list-header p {
  margin: 0;
}

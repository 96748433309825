/* Items */
#items ul {
  margin: 0 0 1rem;
  padding: 0;
}
#items li {
  margin: 0;
  font-size: 1.2rem;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ccc;
}

#items li:last-child {
  border-bottom: 1px solid #ccc;
}
#items li:hover {
  background-color: var(--brand-light);
}
#items li > a,
#items li > span {
  display: block;
  padding: 0.5rem;
  color: var(--foregroud);
  text-decoration: none;
  flex-grow: 1;
}
#items li > a:hover {
  text-decoration: underline;
}
#items li .icon {
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  margin: 5px;
  border: 0;
  border-radius: 3px;

  background-color: var(--background);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
#items li .icon.edit {
  background-image: url("../../../public/icons/edit.png");
}
#items li .icon.delete {
  background-image: url("../../../public/icons/delete.png");
}
#items li .icon.unchecked {
  background-image: url("../../../public/icons/unchecked.png");
}
#items li .icon.done {
  background-image: url("../../../public/icons/done.png");
}

#items li .icon:hover {
  background-color: var(--brand);
}

.button.block {
  text-align: right;
}

form {
  margin: 0 auto;
  width: 100%;
  max-width: 20rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
}
form input {
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  padding: 0.2rem;
}
form button {
  width: 100%;
  text-align: center;
  padding: 0.5rem;
  color: var(--background);
  background: var(--brand);
  border: 0;
  margin: 0 0 0.5rem;
}

p {
  margin: 0 0 1rem;
}
.avatar.image {
  border-radius: 0.5rem;
}

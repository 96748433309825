:root {
  --background: white;
  --foreground: black;
  --brand: #00692f;
  --brand-light: #d5f7e4;
}

html,
body {
  margin: 0;
  padding: 0;
  font: 1em sans-serif;
  color: var(--foreground);
  background: var(--background);
}

body {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  height: 100vh;
}

/* Nav */
nav {
  background: var(--brand);
  padding: 1rem;
}
nav ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
  list-style: none;
}
nav a img {
  text-decoration: none;
  border-radius: 0.2rem;
}

/* Main */
main {
  padding: 1rem;
  overflow: auto;
}

h1,
h2,
h3,
p {
  margin: 0.5rem 0;
}

#authHeader {
  background-color: var(--brand);
  color: var(--background);
  text-align: center;
  margin-bottom: 1rem;
}
#authHeader h1 {
  margin: 0;
  padding: 1rem;
}
